import React from "react"
import Layout from "../components/layout"

const AboutPage = (props) => {
    return (<Layout pageTitle="about">
        <article>
            <h2>
                Me
            </h2>
            <p>I'm a physics PhD student at Heriot-Watt working in <a href="http://hwquantum.org/">Jonathan Leach's group</a> on stuff to do with quantum optics.</p>

            <a href="https://github.com/maxastyler">Here's my github account</a>
        </article>
    </Layout>)
}

export default AboutPage
